import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ejPhoneNumber'
})
export class PhoneNumberPipe implements PipeTransform {

  constructor() { }
  
  transform(value: any): any {
    if (!value) return '';
    var pharray = value.split("-");
    if (pharray.length == 2) {
        if (pharray[0].length > 0 && pharray[1].length > 0)
            return value;
    }
    return '';
  }

}