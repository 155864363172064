import { Component, OnInit, ChangeDetectorRef, ViewRef, ViewChild } from '@angular/core';
import { AuthService } from '../../idsvr/auth.service'
import { Router, ActivatedRoute } from '@angular/router';
import { TenantHomeService } from './tenant-home.service';
import { constants } from '../../helpers/constants';
import { NgForm } from '@angular/forms';
//import { ToasterService } from 'src/ej-shared/services/toastr.service';
//import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2'
import { SharedService } from 'src/ej-shared/services/shared.service';
import { RecaptchaComponent } from 'ng-recaptcha';
import { Title } from '@angular/platform-browser';
import { StorageService } from '../../ej-shared/services/storage.service'
import { HttpClient } from '@angular/common/http';
import { environment, GoogleCaptcha } from 'src/environments/environment';
import { GlobalService } from 'src/ej-shared/services/global.service';
declare var $: any;

@Component({
  selector: 'app-tenant-home',
  templateUrl: './tenant-home.component.html',
  styleUrls: [
    './tenant-home.component.css',
    '../../assets/css/wp/animate.css',
    '../../assets/vendors/general/swiper/css/swiper.min.css',
    '../../assets/vendors/general/bootstrap/dist/css/bootstrap.min.css',
    '../../assets/css/wp/layout.min.css'
  ]
})
export class TenantHomeComponent implements OnInit {
  isLoggedIn: boolean;
  homeViewModel: any = {};
  ejLogoSrc: string = "assets/media/logos/ej_logo.png";
  tenantLogoSrc: string = "";
  isAdmin: boolean = true;
  enquiryModel: any = {};
  saveEnquiryBtnDisabled: boolean = false;
  errorRecaptchaRender: boolean = false;
  gRecaptchaResponse: string = "";
  phoneNumberError = false;
  phoneCountryCodeError = false;
  phoneNumberLengthError = false;
  loading = false;
  @ViewChild('recaptchaRef', { static: false }) recaptchaRef: RecaptchaComponent;
  loadingCountryCodes: boolean;
  lodingTenantInfo: boolean = true;
  isMember: boolean;
  loadSchoolInfo: boolean = true;
  trainerType: any;
  captchaSiteKey = GoogleCaptcha.site_key;
  showSplashScreen: boolean = true;
  showMyHome: boolean = false;

  constructor(
    private readonly authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private readonly tenantHomeService: TenantHomeService,
    private cdRef: ChangeDetectorRef,
    private titleService: Title,
    private storageService: StorageService,
    private readonly http: HttpClient,
    private readonly sharedService: SharedService,
    private readonly globalService: GlobalService
  ) { }

  async ngOnInit() {
    await this.authService.getTenantDetails().then((res: any) => {
      this.trainerType = res.trainerType;
      if (res.trainerType == 1 || res.trainerType == 3) {
        this.detectChanges();
        return this.authService.login();
      }
    });
    if (constants.admin_url.toLowerCase() == constants.host_url.toLowerCase()
      || environment.commonLoginUrl.toLowerCase() == constants.host_url.toLowerCase()
      || this.sharedService.isMember()
      || this.sharedService.isRedirectUrl()) {
      this.authService.login();
    }
    this.titleService.setTitle('eduJournal | Home');
    window.scrollTo(0, 0);
    this.loadingCountryCodes = true;
    await this.getCountryCodes().then(res => {
      this.storageService.setCountryCodes(res);
      this.loadingCountryCodes = false;
    });
    await this.globalService.setCurrency(null);
    await this.globalService.setInternationalCurrency(null);

    this.isLoggedIn = await this.authService.isLoggedin();
    if (window.location.origin.replace('/client', '').toLowerCase() == constants.admin_url.replace('/client', '').toLowerCase()) {
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
    }
    this.isMember = this.sharedService.isMember();
    if (!this.isAdmin && !this.isMember && !this.sharedService.isRedirectUrl) {
      await this.getHomeViewModel();
    }

    this.validatePhoneNumber();

    this.checkDisplayMyHome();
  }

  checkDisplayMyHome() {
    this.showMyHome = (!this.isAdmin && !this.isMember && (this.trainerType == 2));

    //// Disable My Home for Training Inst
    //this.showMyHome = false;

    this.showSplashScreen = !this.showMyHome;
  }

  public login() {
    this.authService.login();
  }

  gotoApplication() {
    this.authService.login();
  }

  async getHomeViewModel() {
    await this.tenantHomeService.getHomeViewModel().then(data => {
      this.homeViewModel = data;
      this.setTenantImgSrc();
      this.lodingTenantInfo = false;
      this.detectChanges();
    });
  }

  setTenantImgSrc() {
    //// Logo update to header disabled for now
    // if (this.homeViewModel && this.homeViewModel.tenantLogo
    //   && this.homeViewModel.tenantLogo.logoRelativePath != null && this.homeViewModel.tenantLogo.logoRelativePath != ""
    //   && this.homeViewModel.tenantLogo.logoName != null && this.homeViewModel.tenantLogo.logoName != "") {
    //   this.tenantLogoSrc = this.homeViewModel.tenantLogo.logoRelativePath + '\\60x60\\' + this.homeViewModel.tenantLogo.logoName;
    // }
    // else {
    //   this.tenantLogoSrc = this.ejLogoSrc;
    // }

    this.tenantLogoSrc = this.ejLogoSrc;
    this.detectChanges();
  }

  courseNav(courseId, wpPostUrl) {
    // disabled for now
    //this.router.navigate(['/courseenquiry', courseId]);
    if (wpPostUrl) {
      window.open(wpPostUrl);
    }
  }

  async saveEnquiryEvent(form: NgForm) {
    if (this.validateGRrecaptcha() && !this.phoneNumberLengthError) {
      this.saveEnquiryBtnDisabled = true;
      await this.saveEnquiry(form);
    }
  }

  async saveEnquiry(form: NgForm) {
    this.loading = true;
    this.detectChanges();
    this.enquiryModel.gRecaptchaResponse = this.gRecaptchaResponse;
    await this.tenantHomeService.saveEnquiry(this.enquiryModel).then(response => {
      this.loading = false;
      if (response.status) {
        //this.toastr.success(response.information);
        Swal.fire(response.information);
        form.resetForm();
        this.saveEnquiryBtnDisabled = false;
        this.recaptchaRef.reset();
        this.enquiryModel.mobile = "";
      }
      else {
        //this.toastr.error(response.information);
        Swal.fire(response.information);
        this.saveEnquiryBtnDisabled = false;
      }
    });
  };

  validateGRrecaptcha() {
    //recaptcha failed validation
    if (this.gRecaptchaResponse.length == 0) {
      this.errorRecaptchaRender = true;
      return false;
    }
    //recaptcha passed validation
    else {
      this.errorRecaptchaRender = false;
      return true;
    }
  }

  resolved(captchaResponse: string) {
    this.gRecaptchaResponse = `${captchaResponse}`;
  }

  receivePhoneNumber(number: string) {
    this.enquiryModel.mobile = number;
    this.validatePhoneNumber();
  }
  receivePhoneNumberError(hasError: boolean) {
    this.phoneNumberLengthError = hasError;
  }

  validatePhoneNumber() {
    if (this.enquiryModel.mobile == undefined || this.enquiryModel.mobile == null || this.enquiryModel.mobile == '') {
      this.phoneNumberError = true;
    }
    else if (this.enquiryModel.mobile.indexOf('-') == -1 || this.enquiryModel.mobile.split('-')[0] == undefined || this.enquiryModel.mobile.split('-')[0] == null || this.enquiryModel.mobile.split('-')[0] == "") {
      this.phoneCountryCodeError = true;
      this.phoneNumberError = false;
    }
    else if (this.enquiryModel.mobile.split('-')[1] == undefined || this.enquiryModel.mobile.split('-')[1] == null || this.enquiryModel.mobile.split('-')[1] == "") {
      this.phoneNumberError = true;
    }
    else {
      this.phoneNumberError = false;
      this.phoneCountryCodeError = false;
    }
  }

  detectChanges() {
    if (this.cdRef && !(this.cdRef as ViewRef).destroyed) {
      this.cdRef.detectChanges();
    }
  }

  async getCountryCodes(): Promise<any> {
    return await this.http.get(constants.url_constants.api + '/api/LayoutApi/GetCountryCodes').toPromise();
  }

  headerLogoClick() {
    window.open(environment.websiteUrl);
  }

  scrollToElement(elemId) {
    var $container = $('#body'),
      $scrollTo = $('#' + elemId);
    $("html, body").animate({
      scrollTop: ($scrollTo.offset() ? $scrollTo.offset().top : 0) - $container.offset().top + $container.scrollTop() - 30
    }, "slow");
  }

  socialMediaClickEvent(url) {
    if (!(url.startsWith('https://') || url.startsWith('http://'))) {
      url = 'https://' + url;
    }
    window.open(url, '_blank');
  }
}